import style from "./Calendar.module.scss"

// imgs import 
import LampSvg from "@assets/img/icons/lampSvg"
import SearchImg from "@assets/img/icons/filter-search.svg"
import { openModal } from "../../../store/modal"
import { useDispatch } from "react-redux"
import { useState } from "react"

const CalendarScreenMobile = () => {
  const dispath = useDispatch()
  const [isFirstTabActive, setIsFirstTabActive] = useState(true)

  return (
    <div className={style.calendar}>
      <div className="container">

        <div className={style.calendarTop}>
          <div className={style.calendarTop__title}>
            Календарь
          </div>
          <div
            onClick={() => dispath(openModal())}
            className={style.calendarTop__filter}>
            <img src={SearchImg} alt="" />
          </div>
        </div>

        <div className={style.calendarBtns}>
          <span className={isFirstTabActive ? '' : style.active}></span>
          <div
            onClick={() => setIsFirstTabActive(true)}
            className={`${style.calendarBtns__item} ${isFirstTabActive ? style.active : ''}`}>
            Запланировано
          </div>
          <div
            onClick={() => setIsFirstTabActive(false)}
            className={`${style.calendarBtns__item} ${!isFirstTabActive ? style.active : ''}`}>
            Завершено
          </div>
        </div>

        <div className={style.calendarContent}>
          <div className={style.none}>
            {LampSvg()}
            Записей нет
          </div>
        </div>

      </div>
    </div>
  )
}

export default CalendarScreenMobile