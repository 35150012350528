import style from "./Home.module.scss"
import { ImgWebp } from "../../helpers/imgwebp"

// imgs import
import CardImg01 from "@assets/img/main/img01.png"
import CardImg01_2x from "@assets/img/main/img01@2x.png"
import CardImg01Webp from "@assets/img/main/img01.webp"
import CardImg02 from "@assets/img/main/img02.png"
import CardImg02_2x from "@assets/img/main/img02@2x.png"
import CardImg02Webp from "@assets/img/main/img02.webp"
import CardImg03 from "@assets/img/main/img03.png"
import CardImg03_2x from "@assets/img/main/img03@2x.png"
import CardImg03Webp from "@assets/img/main/img03.webp"
import CardImg04 from "@assets/img/main/img04.png"
import CardImg04_2x from "@assets/img/main/img04@2x.png"
import CardImg04Webp from "@assets/img/main/img04.webp"
import CardImg05 from "@assets/img/main/img05.png"
import CardImg05_2x from "@assets/img/main/img05@2x.png"
import CardImg05Webp from "@assets/img/main/img05.webp"
import CardImg06 from "@assets/img/main/img06.png"
import CardImg06_2x from "@assets/img/main/img06@2x.png"
import CardImg06Webp from "@assets/img/main/img06.webp"
import CardImg07 from "@assets/img/main/img07.png"
import CardImg07_2x from "@assets/img/main/img07@2x.png"
import CardImg07Webp from "@assets/img/main/img07.webp"
import CardImg08 from "@assets/img/main/img08.png"
import CardImg08_2x from "@assets/img/main/img08@2x.png"
import CardImg08Webp from "@assets/img/main/img08.webp"
import CardImg09 from "@assets/img/main/img09.png"
import CardImg09_2x from "@assets/img/main/img09@2x.png"
import CardImg09Webp from "@assets/img/main/img09.webp"
import CardImg10 from "@assets/img/main/img10.png"
import CardImg10_2x from "@assets/img/main/img10@2x.png"
import CardImg10Webp from "@assets/img/main/img10.webp"
import CardImg11 from "@assets/img/main/img11.png"
import CardImg11_2x from "@assets/img/main/img11@2x.png"
import CardImg11Webp from "@assets/img/main/img11.webp"
import CardImg12 from "@assets/img/main/img12.png"
import CardImg12_2x from "@assets/img/main/img12@2x.png"
import CardImg12Webp from "@assets/img/main/img12.webp"
import StarSvg from "@assets/img/icons/starSvg.js"
import CommentsSvg from "@assets/img/icons/commentsSvg.js"
import heartSvg from "../../assets/img/icons/heartSvg"
import { openModal } from "../../store/modal"
import { useDispatch } from "react-redux"
import { useRef } from "react"
import HeartFullSvg from "../../assets/img/icons/heartFullSvg"

const HomeScreen = () => {
  return (
    <main className={style.main}>
      <div className="container">
        <div className={style.mainBody}>
          <Card
            img={CardImg01}
            img2x={CardImg01_2x}
            imgWebp={CardImg01Webp}
            name={'Александр Евдокимов'}
            price={'3 500'}
            rating={'5.0'}
            commentsCount={'217'}
          />
          <Card
            img={CardImg02}
            img2x={CardImg02_2x}
            imgWebp={CardImg02Webp}
            name={'Валентин Кабанов'}
            price={'2 900'}
            rating={'5.0'}
            commentsCount={'141'}
          />
          <Card
            img={CardImg03}
            img2x={CardImg03_2x}
            imgWebp={CardImg03Webp}
            name={'Филипп Карпов'}
            price={'1 800'}
            rating={'5.0'}
            commentsCount={'138'}
          />
          <Card
            img={CardImg04}
            img2x={CardImg04_2x}
            imgWebp={CardImg04Webp}
            name={'Владислав Щербаков'}
            price={'2 800'}
            rating={'5.0'}
            commentsCount={'119'}
          />
          <Card
            img={CardImg05}
            img2x={CardImg05_2x}
            imgWebp={CardImg05Webp}
            name={'Альберт Исаев'}
            price={'2 500'}
            rating={'5.0'}
            commentsCount={'114'}
          />
          <Card
            img={CardImg06}
            img2x={CardImg06_2x}
            imgWebp={CardImg06Webp}
            name={'Валентин Кабанов'}
            price={'2 700'}
            rating={'4.9'}
            commentsCount={'26'}
          />
          <Card
            img={CardImg07}
            img2x={CardImg07_2x}
            imgWebp={CardImg07Webp}
            name={'Федор Фадеев'}
            price={'3 000'}
            rating={'4.9'}
            commentsCount={'15'}
          />
          <Card
            img={CardImg08}
            img2x={CardImg08_2x}
            imgWebp={CardImg08Webp}
            name={'Артем Русаков'}
            price={'2 800'}
            rating={'5.0'}
            commentsCount={'23'}
          />
        </div>
      </div>
    </main>
  )
}

export const Card = ({ img, img2x, imgWebp, name, price, rating, commentsCount }) => {
  const dispath = useDispatch()
  const heartBtn = useRef()

  const toggleHeart = (e) => {
    console.log(heartBtn);
    if (heartBtn.current.classList.contains(style.active)) {
      heartBtn.current.classList.remove(style.active)
    } else {
      heartBtn.current.classList.add(style.active)
    }
  }

  return (
    <div className={style.card}>
      <div className={style.card__img}>
        <div ref={heartBtn} onClick={e => toggleHeart(e)} className={style.card__imgBtn}>
          {heartSvg()}
          {HeartFullSvg()}
        </div>
        <span onClick={() => dispath(openModal())}>
          <ImgWebp
            src={img}
            src2x={img2x}
            srcWebp={imgWebp}
          />
        </span>
      </div>
      <div onClick={() => dispath(openModal())} className={style.cardInfo}>
        <div className={style.cardInfo__row}>
          <div className={style.cardInfo__name}>
            {name}
          </div>
          <div className={style.cardInfo__content}>
            <div className={style.cardInfo__contentRating}>
              {StarSvg()}
              {rating}
            </div>
            <div className={style.cardInfo__contentComments}>
              {CommentsSvg()}
              {commentsCount}
            </div>
          </div>
        </div>
        <div className={style.cardInfo__row}>
          <div className={style.cardInfo__price}>
            {price} руб.
          </div>
          <a href="#" className={style.cardInfo__link}>
            Полный прайс
          </a>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen