export const ImgWebp = ({
  srcWebp,
  src2x,
  src,
}) => {
  return (
    <picture>
      {/* <source srcset="/images/cereal-box.webp" type="image/webp" />
      <source srcset="/images/cereal-box.jp2" type="image/jp2" /> */}

      <source srcSet={srcWebp} type={'image/webp'} />
      <source srcSet={`${src} 1x, ${src2x} 2x`} />
      <img src={src} alt="" />
    </picture>
  );
};