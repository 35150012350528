import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isModalOpen: false,
  isDonateModalOpen: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
    },
    openDonateModal: (state) => {
      state.isModalOpen = false
      state.isDonateModalOpen = true
    },
    closeDonateModal: (state) => {
      state.isModalOpen = false
      state.isDonateModalOpen = false
    }
  },
})

export const { openModal, closeModal, openDonateModal, closeDonateModal } = modalSlice.actions

export default modalSlice.reducer