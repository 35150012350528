import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import './App.module.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MenuMobile from './components/mobile/Menu/Menu';
import { JoinBetaModal, DonateModal } from './components/Modal/Modal';
import ChatScreen from './screens/Chat/Chat';
import HomeScreen from './screens/Home/Home';
import CalendarScreenMobile from './screens/mobile/Calendar/Calendar';
import ChatScreenMobile from './screens/mobile/Chat/Chat';
import HomeScreenMobile from './screens/mobile/Home/Home';
import SearchScreenMobile from './screens/mobile/Search/Search';
import UserScreenMobile from './screens/mobile/User/User';
import SearchScreen from './screens/Search/Search';

function App() {
  const isMobile = useMediaQuery('(max-width:767px)')

  if (isMobile) {
    return (
      <div className="wrapper">
        <JoinBetaModal />
        <DonateModal />
        <div className="content">
          <MenuMobile />

          <Suspense fallback={<div className='loading'></div>}>
            <Routes>
              <Route path='*' element={<HomeScreenMobile />} />
              <Route path='/' element={<HomeScreenMobile />} />
              <Route path='search' element={<SearchScreenMobile />} />
              <Route path='chat' element={<ChatScreenMobile />} />
              <Route path='chat' element={<ChatScreenMobile />} />
              <Route path='user' element={<UserScreenMobile />} />
              <Route path='calendar' element={<CalendarScreenMobile />} />
            </Routes>
          </Suspense>

        </div>
      </div>
    );
  } else {
    return (
      <div className="wrapper">
        <JoinBetaModal />
        <DonateModal />
        <div className="content">
          <Header />

          <div className="pageContent">
            <Suspense fallback={<div className='loading'></div>}>
              <Routes>
                <Route path='*' element={<HomeScreen />} />
                <Route path='/' element={<HomeScreen />} />
                <Route path='chat' element={<ChatScreen />} />
                <Route path='search' element={<SearchScreen />} />
              </Routes>
            </Suspense>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
