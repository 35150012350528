import style from "./Menu.module.scss"

// imgs import
import SearchSvg from "@assets/img/icons/searchSvg"
import CalendarSvg from "@assets/img/icons/calendarSvg"
import DotsSvg from "@assets/img/icons/dotsSvg"
import MessagesSvg from "@assets/img/icons/messageSvg"
import UserSvg from "@assets/img/icons/userSvg"
import { useLocation, useNavigate } from "react-router-dom"

const MenuMobile = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const toPage = (page) => {
    navigate(page)
  }

  return (
    <div className={style.menu}>
      <div className={style.menuBody}>
        <div
          onClick={() => toPage('search')}
          className={`
        ${style.menuBody__item}
        ${location.pathname === '/search' ? style.active : ''}`}>
          {SearchSvg()}
        </div>
        <div
          onClick={() => toPage('calendar')}
          className={`
        ${style.menuBody__item}
        ${location.pathname === '/calendar' ? style.active : ''}`}>
          {CalendarSvg()}
        </div>
        <div
          onClick={() => toPage('/')}
          className={`
        ${style.menuBody__main} 
        ${location.pathname === '/' ? style.active : ''}`}
        >
          <span>
            {DotsSvg()}
          </span>
        </div>
        <div
          onClick={() => toPage('chat')}
          className={`
        ${style.menuBody__item} 
        ${location.pathname === '/chat' ? style.active : ''}`}>
          {MessagesSvg()}
        </div>
        <div
          onClick={() => toPage('user')}
          className={`
        ${style.menuBody__item}
        ${location.pathname === '/user' ? style.active : ''}`}>
          {UserSvg()}
        </div>
      </div>
    </div>
  )
}

export default MenuMobile