import { Card } from "../Home/Home"
import style from "./Search.module.scss"

// imgs import
import CardImg01 from "@assets/img/main/img01.png"
import CardImg01_2x from "@assets/img/main/img01@2x.png"
import CardImg01Webp from "@assets/img/main/img01.webp"
import CardImg02 from "@assets/img/main/img02.png"
import CardImg02_2x from "@assets/img/main/img02@2x.png"
import CardImg02Webp from "@assets/img/main/img02.webp"
import CardImg03 from "@assets/img/main/img03.png"
import CardImg03_2x from "@assets/img/main/img03@2x.png"
import CardImg03Webp from "@assets/img/main/img03.webp"
import CardImg04 from "@assets/img/main/img04.png"
import CardImg04_2x from "@assets/img/main/img04@2x.png"
import CardImg04Webp from "@assets/img/main/img04.webp"
import CardImg05 from "@assets/img/main/img05.png"
import CardImg05_2x from "@assets/img/main/img05@2x.png"
import CardImg05Webp from "@assets/img/main/img05.webp"
import CardImg06 from "@assets/img/main/img06.png"
import CardImg06_2x from "@assets/img/main/img06@2x.png"
import CardImg06Webp from "@assets/img/main/img06.webp"
import CardImg07 from "@assets/img/main/img07.png"
import CardImg07_2x from "@assets/img/main/img07@2x.png"
import CardImg07Webp from "@assets/img/main/img07.webp"
import CardImg08 from "@assets/img/main/img08.png"
import CardImg08_2x from "@assets/img/main/img08@2x.png"
import CardImg08Webp from "@assets/img/main/img08.webp"
import CardImg09 from "@assets/img/main/img09.png"
import CardImg09_2x from "@assets/img/main/img09@2x.png"
import CardImg09Webp from "@assets/img/main/img09.webp"
import CardImg10 from "@assets/img/main/img10.png"
import CardImg10_2x from "@assets/img/main/img10@2x.png"
import CardImg10Webp from "@assets/img/main/img10.webp"
import CardImg11 from "@assets/img/main/img11.png"
import CardImg11_2x from "@assets/img/main/img11@2x.png"
import CardImg11Webp from "@assets/img/main/img11.webp"
import CardImg12 from "@assets/img/main/img12.png"
import CardImg12_2x from "@assets/img/main/img12@2x.png"
import CardImg12Webp from "@assets/img/main/img12.webp"

const SearchScreen = () => {
  return (
    <div className={style.search}>
      <div className="container">
        <div className={style.searchBody}>
          <Card
            img={CardImg09}
            img2x={CardImg09_2x}
            imgWebp={CardImg09Webp}
            name={'Эрик Логинов'}
            price={'1 500'}
            rating={'4.7'}
            commentsCount={'17'}
          />
          <Card
            img={CardImg10}
            img2x={CardImg10_2x}
            imgWebp={CardImg10Webp}
            name={'Валентин Кабанов'}
            price={'2 900'}
            rating={'5.0'}
            commentsCount={'141'}
          />
          <Card
            img={CardImg11}
            img2x={CardImg11_2x}
            imgWebp={CardImg11Webp}
            name={'Егор Горбачев'}
            price={'1 000'}
            rating={'4.5'}
            commentsCount={'34'}
          />
          <Card
            img={CardImg12}
            img2x={CardImg12_2x}
            imgWebp={CardImg12Webp}
            name={'Александр Евдокимов'}
            price={'1 800'}
            rating={'4.7'}
            commentsCount={'33'}
          />
          <Card
            img={CardImg05}
            img2x={CardImg05_2x}
            imgWebp={CardImg05Webp}
            name={'Альберт Исаев'}
            price={'2 500'}
            rating={'5.0'}
            commentsCount={'214'}
          />
          <Card
            img={CardImg06}
            img2x={CardImg06_2x}
            imgWebp={CardImg06Webp}
            name={'Герасим Носов'}
            price={'2 700'}
            rating={'4.9'}
            commentsCount={'26'}
          />
          <Card
            img={CardImg07}
            img2x={CardImg07_2x}
            imgWebp={CardImg07Webp}
            name={'Федор Фадеев'}
            price={'3 000'}
            rating={'4.9'}
            commentsCount={'15'}
          />
          <Card
            img={CardImg08}
            img2x={CardImg08_2x}
            imgWebp={CardImg08Webp}
            name={'Артем Русаков'}
            price={'1 500'}
            rating={'4.8'}
            commentsCount={'23'}
          />
          <Card
            img={CardImg01}
            img2x={CardImg01_2x}
            imgWebp={CardImg01Webp}
            name={'Александр Евдокимов'}
            price={'3 500'}
            rating={'5.0'}
            commentsCount={'217'}
          />
          <Card
            img={CardImg02}
            img2x={CardImg02_2x}
            imgWebp={CardImg02Webp}
            name={'Валентин Кабанов'}
            price={'2 900'}
            rating={'5.0'}
            commentsCount={'141'}
          />
          <Card
            img={CardImg03}
            img2x={CardImg03_2x}
            imgWebp={CardImg03Webp}
            name={'Филипп Карпов'}
            price={'1 800'}
            rating={'5.0'}
            commentsCount={'138'}
          />
          <Card
            img={CardImg04}
            img2x={CardImg04_2x}
            imgWebp={CardImg04Webp}
            name={'Владислав Щербаков'}
            price={'2 800'}
            rating={'5.0'}
            commentsCount={'119'}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchScreen