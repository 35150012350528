import style from "./Chat.module.scss"

// imgs import 
import LampSvg from "@assets/img/icons/lampSvg"

const ChatScreenMobile = () => {
  return (
    <div className={style.chat}>
      <div className="container">
        <div className={style.chatBody}>
          <div className={style.chatBody__title}>
            Чаты
          </div>
          <div className={style.chatBody__content}>
            <div className={style.none}>
              {LampSvg()}
              У вас пока нет чатов
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatScreenMobile