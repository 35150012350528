
const commentsSvg = () => <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.49967 12.3553C6.12592 12.3553 5.77384 12.1658 5.52467 11.8353L4.71217 10.752C4.69592 10.7303 4.63092 10.7033 4.60384 10.6978H4.33301C2.07426 10.6978 0.676758 10.0858 0.676758 7.04159V4.33325C0.676758 1.93909 1.93884 0.677002 4.33301 0.677002H8.66634C11.0605 0.677002 12.3226 1.93909 12.3226 4.33325V7.04159C12.3226 9.43575 11.0605 10.6978 8.66634 10.6978H8.39551C8.35217 10.6978 8.31426 10.7195 8.28717 10.752L7.47467 11.8353C7.22551 12.1658 6.87342 12.3553 6.49967 12.3553ZM4.33301 1.4895C2.39384 1.4895 1.48926 2.39409 1.48926 4.33325V7.04159C1.48926 9.48992 2.32884 9.88534 4.33301 9.88534H4.60384C4.88009 9.88534 5.19426 10.0424 5.36217 10.2645L6.17467 11.3478C6.36426 11.597 6.63509 11.597 6.82467 11.3478L7.63717 10.2645C7.81592 10.0262 8.09759 9.88534 8.39551 9.88534H8.66634C10.6055 9.88534 11.5101 8.98075 11.5101 7.04159V4.33325C11.5101 2.39409 10.6055 1.4895 8.66634 1.4895H4.33301Z" fill="#2F3147" />
  <path d="M9.20866 4.7395H3.79199C3.56991 4.7395 3.38574 4.55534 3.38574 4.33325C3.38574 4.11117 3.56991 3.927 3.79199 3.927H9.20866C9.43074 3.927 9.61491 4.11117 9.61491 4.33325C9.61491 4.55534 9.43074 4.7395 9.20866 4.7395Z" fill="#2F3147" />
  <path d="M7.04199 7.448H3.79199C3.56991 7.448 3.38574 7.26383 3.38574 7.04175C3.38574 6.81966 3.56991 6.6355 3.79199 6.6355H7.04199C7.26408 6.6355 7.44824 6.81966 7.44824 7.04175C7.44824 7.26383 7.26408 7.448 7.04199 7.448Z" fill="#2F3147" />
  <path d="M6.49967 12.3553C6.12592 12.3553 5.77384 12.1658 5.52467 11.8353L4.71217 10.752C4.69592 10.7303 4.63092 10.7033 4.60384 10.6978H4.33301C2.07426 10.6978 0.676758 10.0858 0.676758 7.04159V4.33325C0.676758 1.93909 1.93884 0.677002 4.33301 0.677002H8.66634C11.0605 0.677002 12.3226 1.93909 12.3226 4.33325V7.04159C12.3226 9.43575 11.0605 10.6978 8.66634 10.6978H8.39551C8.35217 10.6978 8.31426 10.7195 8.28717 10.752L7.47467 11.8353C7.22551 12.1658 6.87342 12.3553 6.49967 12.3553ZM4.33301 1.4895C2.39384 1.4895 1.48926 2.39409 1.48926 4.33325V7.04159C1.48926 9.48992 2.32884 9.88534 4.33301 9.88534H4.60384C4.88009 9.88534 5.19426 10.0424 5.36217 10.2645L6.17467 11.3478C6.36426 11.597 6.63509 11.597 6.82467 11.3478L7.63717 10.2645C7.81592 10.0262 8.09759 9.88534 8.39551 9.88534H8.66634C10.6055 9.88534 11.5101 8.98075 11.5101 7.04159V4.33325C11.5101 2.39409 10.6055 1.4895 8.66634 1.4895H4.33301Z" fill="#2F3147" />
  <path d="M6.49967 12.3553C6.12592 12.3553 5.77384 12.1658 5.52467 11.8353L4.71217 10.752C4.69592 10.7303 4.63092 10.7033 4.60384 10.6978H4.33301C2.07426 10.6978 0.676758 10.0858 0.676758 7.04159V4.33325C0.676758 1.93909 1.93884 0.677002 4.33301 0.677002H8.66634C11.0605 0.677002 12.3226 1.93909 12.3226 4.33325V7.04159C12.3226 9.43575 11.0605 10.6978 8.66634 10.6978H8.39551C8.35217 10.6978 8.31426 10.7195 8.28717 10.752L7.47467 11.8353C7.22551 12.1658 6.87342 12.3553 6.49967 12.3553ZM4.33301 1.4895C2.39384 1.4895 1.48926 2.39409 1.48926 4.33325V7.04159C1.48926 9.48992 2.32884 9.88534 4.33301 9.88534H4.60384C4.88009 9.88534 5.19426 10.0424 5.36217 10.2645L6.17467 11.3478C6.36426 11.597 6.63509 11.597 6.82467 11.3478L7.63717 10.2645C7.81592 10.0262 8.09759 9.88534 8.39551 9.88534H8.66634C10.6055 9.88534 11.5101 8.98075 11.5101 7.04159V4.33325C11.5101 2.39409 10.6055 1.4895 8.66634 1.4895H4.33301Z" fill="#2D91D9" />
  <path d="M9.20866 4.7395H3.79199C3.56991 4.7395 3.38574 4.55534 3.38574 4.33325C3.38574 4.11117 3.56991 3.927 3.79199 3.927H9.20866C9.43074 3.927 9.61491 4.11117 9.61491 4.33325C9.61491 4.55534 9.43074 4.7395 9.20866 4.7395Z" fill="#2F3147" />
  <path d="M9.20866 4.7395H3.79199C3.56991 4.7395 3.38574 4.55534 3.38574 4.33325C3.38574 4.11117 3.56991 3.927 3.79199 3.927H9.20866C9.43074 3.927 9.61491 4.11117 9.61491 4.33325C9.61491 4.55534 9.43074 4.7395 9.20866 4.7395Z" fill="#2D91D9" />
  <path d="M7.04199 7.448H3.79199C3.56991 7.448 3.38574 7.26383 3.38574 7.04175C3.38574 6.81966 3.56991 6.6355 3.79199 6.6355H7.04199C7.26408 6.6355 7.44824 6.81966 7.44824 7.04175C7.44824 7.26383 7.26408 7.448 7.04199 7.448Z" fill="#2F3147" />
  <path d="M7.04199 7.448H3.79199C3.56991 7.448 3.38574 7.26383 3.38574 7.04175C3.38574 6.81966 3.56991 6.6355 3.79199 6.6355H7.04199C7.26408 6.6355 7.44824 6.81966 7.44824 7.04175C7.44824 7.26383 7.26408 7.448 7.04199 7.448Z" fill="#2D91D9" />
</svg>

export default commentsSvg