import style from "./Home.module.scss"
import { ImgWebp } from "../../../helpers/imgwebp"
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";

// imgs import
import crownImg from "@assets/img/icons/crown.svg"
import profileImg01 from "@assets/img/main/mobile/img01.png"
import profileImg01Webp from "@assets/img/main/mobile/img01.webp"
import profileImg02 from "@assets/img/main/mobile/img02.png"
import profileImg02Webp from "@assets/img/main/mobile/img02.webp"
import profileImg03 from "@assets/img/main/mobile/img03.png"
import profileImg03Webp from "@assets/img/main/mobile/img03.webp"
import profileImg04 from "@assets/img/main/mobile/img04.png"
import profileImg04Webp from "@assets/img/main/mobile/img04.webp"
import profileImg05 from "@assets/img/main/mobile/img05.png"
import profileImg05Webp from "@assets/img/main/mobile/img05.webp"
import profileImg06 from "@assets/img/main/mobile/img06.png"
import profileImg06Webp from "@assets/img/main/mobile/img06.webp"
import profileImg07 from "@assets/img/main/mobile/img07.png"
import profileImg07Webp from "@assets/img/main/mobile/img07.webp"
import profileImg08 from "@assets/img/main/mobile/img08.png"
import profileImg08Webp from "@assets/img/main/mobile/img08.webp"
import profileImg09 from "@assets/img/main/mobile/img09.png"
import profileImg09Webp from "@assets/img/main/mobile/img09.webp"
import profileImg10 from "@assets/img/main/mobile/img10.png"
import profileImg10Webp from "@assets/img/main/mobile/img10.webp"
import profileImg11 from "@assets/img/main/mobile/img11.png"
import profileImg11Webp from "@assets/img/main/mobile/img11.webp"
import profileImg12 from "@assets/img/main/mobile/img12.png"
import profileImg12Webp from "@assets/img/main/mobile/img12.webp"
import profileImg13 from "@assets/img/main/mobile/img13.png"
import profileImg13Webp from "@assets/img/main/mobile/img13.webp"
import profileImg14 from "@assets/img/main/mobile/img14.png"
import profileImg14Webp from "@assets/img/main/mobile/img14.webp"
import profileImg15 from "@assets/img/main/mobile/img15.png"
import profileImg15Webp from "@assets/img/main/mobile/img15.webp"
import StarSvg from "@assets/img/icons/starSvg.js"
import CommentsSvg from "@assets/img/icons/commentsSvg.js"
import { Link } from "react-router-dom";
import { openModal } from "../../../store/modal";
import { useDispatch } from "react-redux";

const HomeScreenMobile = () => {
  return (
    <>
      <SearchBlock />
      <div className={style.content}>
        <PopularRow />
        <NearlyRow />
        <TodayRow />
      </div>
    </>
  )
}

const SearchBlock = () => {
  return (
    <div className={style.searchBlock}>
      <div className="container">
        <div className={style.searchBlock__content}>
          <Link to={'search'} className={`${style.searchBlock__contentItem}`}>
            Начать поиск
          </Link>
          <Link to={'search'} className={`${style.searchBlock__contentItem} ${style._border}`}>
            Аудит лечения
          </Link>
        </div>
      </div>
    </div>
  )
}

const PopularRow = () => {
  const dispath = useDispatch()
  return (
    <div className={style.row}>
      <div className="container">
        <div className={style.row__title}>
          <img src={crownImg} alt="" />
          Самые популярные
        </div>
        <div className={style.rowBody}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={35}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode]}
            className="mySwiper"
          >
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg01Webp}
                    src2x={profileImg01}
                    src={profileImg01}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Александр
                  Евдокимов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    5.0
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    217
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg02Webp}
                    src2x={profileImg02}
                    src={profileImg02}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Валентин
                  Кабанов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    5.0
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    141
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg03Webp}
                    src2x={profileImg03}
                    src={profileImg03}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Филипп
                  Карпов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    5.0
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    138
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg04Webp}
                    src2x={profileImg04}
                    src={profileImg04}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Владислав
                  Щербаков
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    5.0
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    119
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg05Webp}
                    src2x={profileImg05}
                    src={profileImg05}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Альберт
                  Исаев
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    5.0
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    119
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

const NearlyRow = () => {
  const dispath = useDispatch()

  return (
    <div className={style.row}>
      <div className="container">
        <div className={style.row__title}>
          Рядом с вами
        </div>
        <div className={style.rowBody}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={35}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode]}
            className="mySwiper"
          >
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg06Webp}
                    src2x={profileImg06}
                    src={profileImg06}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Герасим
                  Носов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.9
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    26
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg07Webp}
                    src2x={profileImg07}
                    src={profileImg07}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Федор
                  Фадеев
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.9
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    15
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg08Webp}
                    src2x={profileImg08}
                    src={profileImg08}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Артем
                  Русаков
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.8
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    23
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg09Webp}
                    src2x={profileImg09}
                    src={profileImg09}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Эрик
                  Логинов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.7
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    17
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg10Webp}
                    src2x={profileImg10}
                    src={profileImg10}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Роберт
                  Орлов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.7
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    13
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

const TodayRow = () => {
  const dispath = useDispatch()

  return (
    <div className={style.row}>
      <div className="container">
        <div className={style.row__title}>
          Есть запись на сегодня
        </div>
        <div className={style.rowBody}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={35}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode]}
            className="mySwiper"
          >
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg11Webp}
                    src2x={profileImg11}
                    src={profileImg11}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Павел
                  Антонов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.8
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    44
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg12Webp}
                    src2x={profileImg12}
                    src={profileImg12}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Кирилл
                  Степанов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.8
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    26
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg13Webp}
                    src2x={profileImg13}
                    src={profileImg13}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Давид
                  Туров
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.8
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    18
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg14Webp}
                    src2x={profileImg14}
                    src={profileImg14}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Егор
                  Горбачёв
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.5
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    34
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div onClick={() => dispath(openModal())} className={style.rowBody__item}>
                <div className={style.rowBody__itemImg}>
                  <ImgWebp
                    srcWebp={profileImg15Webp}
                    src2x={profileImg15}
                    src={profileImg15}
                  />
                </div>
                <div className={style.rowBody__itemName}>
                  Арсен
                  Сафонов
                </div>
                <div className={style.rowBody__itemStats}>
                  <div className={style.rating}>
                    {StarSvg()}
                    4.6
                  </div>
                  <div className={style.comments}>
                    {CommentsSvg()}
                    6
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default HomeScreenMobile