import style from "./Search.module.scss"
import { ImgWebp } from "../../../helpers/imgwebp"
import { useRef, useState } from "react"

// imgs import
import lensImg from "@assets/img/icons/lens.svg"
import optionsImg from "@assets/img/icons/options.svg"
import StarSvg from "@assets/img/icons/starSvg.js"
import CommentsSvg from "@assets/img/icons/commentsSvg.js"
import profileImg01 from "@assets/img/main/mobile/img01.png"
import profileImg01Webp from "@assets/img/main/mobile/img01.webp"
import profileImg02 from "@assets/img/main/mobile/img02.png"
import profileImg02Webp from "@assets/img/main/mobile/img02.webp"
import profileImg03 from "@assets/img/main/mobile/img03.png"
import profileImg03Webp from "@assets/img/main/mobile/img03.webp"
import profileImg04 from "@assets/img/main/mobile/img04.png"
import profileImg04Webp from "@assets/img/main/mobile/img04.webp"
import profileImg05 from "@assets/img/main/mobile/img05.png"
import profileImg05Webp from "@assets/img/main/mobile/img05.webp"
import profileImg06 from "@assets/img/main/mobile/img06.png"
import profileImg06Webp from "@assets/img/main/mobile/img06.webp"
import profileImg07 from "@assets/img/main/mobile/img07.png"
import profileImg07Webp from "@assets/img/main/mobile/img07.webp"
import profileImg08 from "@assets/img/main/mobile/img08.png"
import profileImg08Webp from "@assets/img/main/mobile/img08.webp"
import profileImg09 from "@assets/img/main/mobile/img09.png"
import profileImg09Webp from "@assets/img/main/mobile/img09.webp"
import profileImg10 from "@assets/img/main/mobile/img10.png"
import profileImg10Webp from "@assets/img/main/mobile/img10.webp"
import profileImg11 from "@assets/img/main/mobile/img11.png"
import profileImg11Webp from "@assets/img/main/mobile/img11.webp"
import profileImg12 from "@assets/img/main/mobile/img12.png"
import profileImg12Webp from "@assets/img/main/mobile/img12.webp"
import profileImg13 from "@assets/img/main/mobile/img13.png"
import profileImg13Webp from "@assets/img/main/mobile/img13.webp"
import profileImg14 from "@assets/img/main/mobile/img14.png"
import profileImg14Webp from "@assets/img/main/mobile/img14.webp"
import profileImg15 from "@assets/img/main/mobile/img15.png"
import profileImg15Webp from "@assets/img/main/mobile/img15.webp"
import escapeImg from "@assets/img/icons/escape.svg"
import XImg from "@assets/img/icons/ex_blue.svg"
import LampSvg from "@assets/img/icons/lampSvg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { openModal } from "../../../store/modal"
import { useDispatch } from "react-redux"


const dateObject = {
  november: {
    monthName: 'Ноябрь',
    numbers: [{ num: '', isDisable: true }, { num: 1, isDisable: true }, { num: 2, isDisable: true }, { num: 3, isDisable: true }, { num: 4, isDisable: true }, { num: 5, isDisable: true }, { num: 6, isDisable: true }, { num: 7, isDisable: true }, { num: 8, isDisable: true }, { num: 9, isDisable: true }, { num: 10, isDisable: true }, { num: 11, isDisable: true }, { num: 12, isDisable: true }, { num: 13, isDisable: true }, { num: 14, isDisable: true }, { num: 15, isDisable: true }, { num: 16, isDisable: true }, { num: 17, isDisable: true }, { num: 18, isDisable: true }, { num: 19, isDisable: true }, { num: 20, isDisable: true }, { num: 21, isDisable: true }, { num: 22, isDisable: true }, { num: 23, isDisable: true }, { num: 24, isDisable: true }, { num: 25, isDisable: true }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }]
  },
  december: {
    monthName: 'Декабрь',
    numbers: [{ num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: 1, isDisable: false }, { num: 2, isDisable: false }, { num: 3, isDisable: false }, { num: 4, isDisable: false }, { num: 5, isDisable: false }, { num: 6, isDisable: false }, { num: 7, isDisable: false }, { num: 8, isDisable: false }, { num: 9, isDisable: false }, { num: 10, isDisable: false }, { num: 11, isDisable: false }, { num: 12, isDisable: false }, { num: 13, isDisable: false }, { num: 14, isDisable: false }, { num: 15, isDisable: false }, { num: 16, isDisable: false }, { num: 17, isDisable: false }, { num: 18, isDisable: false }, { num: 19, isDisable: false }, { num: 20, isDisable: false }, { num: 21, isDisable: false }, { num: 22, isDisable: false }, { num: 23, isDisable: false }, { num: 24, isDisable: false }, { num: 25, isDisable: false }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }, { num: 31, isDisable: false }]
  },
  january: {
    monthName: 'Январь',
    numbers: [{ num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: 1, isDisable: false }, { num: 2, isDisable: false }, { num: 3, isDisable: false }, { num: 4, isDisable: false }, { num: 5, isDisable: false }, { num: 6, isDisable: false }, { num: 7, isDisable: false }, { num: 8, isDisable: false }, { num: 9, isDisable: false }, { num: 10, isDisable: false }, { num: 11, isDisable: false }, { num: 12, isDisable: false }, { num: 13, isDisable: false }, { num: 14, isDisable: false }, { num: 15, isDisable: false }, { num: 16, isDisable: false }, { num: 17, isDisable: false }, { num: 18, isDisable: false }, { num: 19, isDisable: false }, { num: 20, isDisable: false }, { num: 21, isDisable: false }, { num: 22, isDisable: false }, { num: 23, isDisable: false }, { num: 24, isDisable: false }, { num: 25, isDisable: false }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }, { num: 31, isDisable: false }]
  },
}

const SearchScreenMobile = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isSearchTabOpen, setIsSearchTabOpen] = useState(true)
  const [isDataTabOpen, setIsDataTabOpen] = useState(false)
  const [isBtnsOpen, setIsBtnsOpen] = useState(false)
  const [isNextBtnActive, setIsNextBtnActive] = useState(false)
  const [isNextBtnActive2, setIsNextBtnActive2] = useState(false)
  const [isNextBtnActive3, setIsNextBtnActive3] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const searchInput = useRef('')
  const [currentDate, setCurrentDate] = useState({ date: null, month: 'Декабрь' })
  const [whoSelected, setWhoSelected] = useState(null)
  const checkboxes = useRef([])

  const openTab = (tab) => {
    if (tab === 1) {
      setIsSearchTabOpen(true)
      setIsDataTabOpen(false)
      setIsBtnsOpen(false)
    } else if (tab === 3) {
      setIsSearchTabOpen(false)
      setIsDataTabOpen(false)
      setIsBtnsOpen(true)
    }
    else {
      setIsSearchTabOpen(false)
      setIsDataTabOpen(true)
      setIsBtnsOpen(false)
    }
  }

  const nextBtnActivateChech = () => {
    console.log(searchInput.current.value);
    if (searchInput.current.value.length > 2) {
      setIsNextBtnActive(true)
    } else {
      setIsNextBtnActive(false)
    }
  }

  const toggleDate = (e) => {
    if (!e.target.classList.contains(style._disable)) {
      setIsNextBtnActive3(true)
      const dateList = document.querySelectorAll('._calendar__item')
      let month = e.target.dataset.month.slice(0, 3)
      setCurrentDate({ date: e.target.innerHTML, month: `${month}.` })
      if (e.target.classList.contains(style._active)) {
        e.target.classList.remove(style._active)
      } else {
        dateList.forEach(item => {
          item.classList.remove(style._active)
        })
        e.target.classList.add(style._active)
      }
    }
  }

  const nextBtn = () => {
    setIsSearchTabOpen(false)
    setIsDataTabOpen(true)
  }
  const nextBtn2 = () => {
    setIsSearchTabOpen(false)
    setIsDataTabOpen(false)
  }

  const clearCheckboxes = () => {
    checkboxes.current.forEach(item => item.checked = false)
    setIsNextBtnActive(false)
  }
  const clearCalendar = () => {
    setIsNextBtnActive3(false)
    setCurrentDate({ date: null, month: null })
    const dateList = document.querySelectorAll('._calendar__item')
    dateList.forEach(item => {
      item.classList.remove(style._active)
    })
  }

  const clearQuery = () => {
    clearCheckboxes()
    clearCalendar()
  }

  return (
    <>
      {/* <SearchTop /> */}

      <div onClick={() => setIsSearchOpen(true)} className={style.top}>
        <div className="container">
          <div className={style.topBody}>
            <div className={style.topBody__lens}>
              <img src={lensImg} alt="" />
            </div>
            <div className={style.topBody__search}>
              <span>
                {/* {whoSelected === null ? 'Искать?' : `${whoSelected}`} */}
                {whoSelected === null ? '' : `${whoSelected}`}
              </span>
              {currentDate.date === null ? 'Поиск специалиста' : `${currentDate.date} ${currentDate.month}`}
            </div>
            <div className={style.topBody__options}>
              <img src={optionsImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className={`${style.modal} ${isSearchOpen ? style._active : ''}`}>
        <div className="container">
          <div className={style.modalTop}>
            <div onClick={() => setIsSearchOpen(false)} className={style.modalTop__close}>
              <img src={escapeImg} alt="" />
            </div>
            <div className={style.modalTop__tab}>
              <div
                onClick={() => setCurrentTab(1)}
                className={`
              ${style.modalTop__tabItem} 
              ${currentTab === 1 ? style._active : ''
                  }`}>
                Запись
              </div>
              <div
                onClick={() => setCurrentTab(2)}
                className={`
              ${style.modalTop__tabItem}
              ${currentTab === 2 ? style._active : ''}
              `}>
                Аудит
              </div>
            </div>
          </div>
          <div className={`
          ${style.modalContent} 
          ${currentTab === 1 ? style._active : ''}
          `}>
            <div className={style.modalContent__item}>
              <div
                onClick={() => openTab(1)}
                className={`${style.modalContent__itemBtn} ${!isSearchTabOpen ? '' : style._closed}`}>
                <div className={style.title}>
                  Кто
                </div>
                <div className={style.value}>
                  Выбран
                </div>
              </div>
              <div className={`${style.modalContent__itemContent} ${isSearchTabOpen ? style._opened : ''}`}>
                <div className={style.modal__title}>
                  Кого вы хотите найти?
                </div>
                <label className={style.modal__search}>
                  <img src={lensImg} alt="" />
                  <input onInput={() => nextBtnActivateChech()} ref={searchInput} type="text" placeholder="Поиск стоматолога" />
                </label>
                <div className={style.modal__check}>
                  <label onClick={() => {
                    setWhoSelected('Стоматолог-терапевт')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[0] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Стоматолог-терапевт
                  </label>
                  <label onClick={() => {
                    setWhoSelected('Стоматолог-пародонтолог')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[1] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Стоматолог-пародонтолог
                  </label>
                  <label onClick={() => {
                    setWhoSelected('Стоматолог-ортопед')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[2] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Стоматолог-ортопед
                  </label>
                  <label onClick={() => {
                    setWhoSelected('Стоматолог-имплантолог')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[3] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Стоматолог-имплантолог
                  </label>
                  <label onClick={() => {
                    setWhoSelected('Стоматолог-хирург')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[4] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Стоматолог-хирург
                  </label>
                  <label onClick={() => {
                    setWhoSelected('Детский стоматолог')
                    setIsNextBtnActive(true)
                  }}>
                    <input ref={el => checkboxes.current[5] = el} type="checkbox" className="_checkboxItem" />
                    <span className={style.checkbox}>
                      <img src={XImg} alt="" />
                    </span>
                    Детский стоматолог
                  </label>
                </div>
                <div className={style.modal__btns}>
                  <div onClick={clearCheckboxes}
                    className={`${style.modal__btnsItem} ${style._cancel}`}>
                    Очистить
                  </div>
                  <div
                    onClick={() => nextBtn()}
                    className={`
                  ${style.modal__btnsItem} 
                  ${style._blue} 
                  ${isNextBtnActive ? style._active : ''}`}>
                    Далее
                  </div>
                </div>
              </div>
            </div>
            <div className={style.modalContent__item}>
              <div
                onClick={() => openTab(2)}
                className={`${style.modalContent__itemBtn} ${!isDataTabOpen ? '' : style._closed}`}>
                <div className={style.title}>
                  Когда
                </div>
                <div className={style.value}>
                  {currentDate.date === null ? 'Дата' : `${currentDate.date} ${currentDate.month}`}
                </div>
              </div>
              <div className={`${style.modalContent__itemContent} ${isDataTabOpen ? style._opened : ''}`}>
                <div className={style.modal__title}>
                  Дата приема
                </div>

                <Swiper
                  spaceBetween={46}
                  slidesPerView={1}
                // autoHeight={true}
                >
                  <SwiperSlide>
                    <div className={style._calendar}>
                      <div className={style._calendarBody__week}>
                        <div className={style._calendarBody__weekItem}>
                          Пн
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Ср
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Чт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Пт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Сб
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вс
                        </div>
                      </div>
                      <div className={style._calendar__title}>
                        {dateObject.november.monthName} 2022 г.
                      </div>
                      <div className={style._calendarBody}>
                        <div className={style._calendarBody__content}>
                          {dateObject.november.numbers.map((item, index) => <div key={index}
                            onClick={(e) => toggleDate(e)}
                            data-month={dateObject.november.monthName}
                            className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.november.numbers[index].isDisable ? style._disable : ''}
                                `}>
                            {dateObject.november.numbers[index].num}
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={style._calendar}>
                      <div className={style._calendarBody__week}>
                        <div className={style._calendarBody__weekItem}>
                          Пн
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Ср
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Чт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Пт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Сб
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вс
                        </div>
                      </div>
                      <div className={style._calendar__title}>
                        {dateObject.december.monthName} 2022 г.
                      </div>
                      <div className={style._calendarBody}>
                        <div className={style._calendarBody__content}>
                          {dateObject.december.numbers.map((item, index) => <div key={index}
                            data-month={dateObject.december.monthName}
                            onClick={(e) => toggleDate(e)}
                            className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.december.numbers[index].isDisable ? style._disable : ''}
                                `}>
                            {dateObject.december.numbers[index].num}
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={style._calendar}>
                      <div className={style._calendarBody__week}>
                        <div className={style._calendarBody__weekItem}>
                          Пн
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Ср
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Чт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Пт
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Сб
                        </div>
                        <div className={style._calendarBody__weekItem}>
                          Вс
                        </div>
                      </div>
                      <div className={style._calendar__title}>
                        {dateObject.january.monthName} 2023 г.
                      </div>
                      <div className={style._calendarBody}>
                        <div className={style._calendarBody__content}>
                          {dateObject.january.numbers.map((item, index) => <div key={index}
                            data-month={dateObject.january.monthName}
                            onClick={(e) => toggleDate(e)}
                            className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.january.numbers[index].isDisable ? style._disable : ''}
                                `}>
                            {dateObject.january.numbers[index].num}
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>

                <div className={style.modal__btns}>
                  <div onClick={clearCalendar} className={`${style.modal__btnsItem} ${style._cancel}`}>
                    Очистить
                  </div>
                  <div onClick={() => openTab(3)} className={`${style.modal__btnsItem} ${style._blue} ${currentDate.date !== null ? style._active : ''}`}>
                    Далее
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.modalBtns} ${isBtnsOpen ? style._open : ''}`}>
              <div onClick={clearQuery} className={`${style.modalBtns__item} ${style._cancel}`}>
                Очистить
              </div>
              <div
                onClick={() => (setIsSearchOpen(false))}
                className={`${style.modalBtns__item} ${style._blue} ${isNextBtnActive3 ? style._active : ''}`}>
                Искать
              </div>
            </div>
          </div>
          <div className={`
          ${style.modalContent} 
          ${currentTab === 2 ? style._active : ''}
          `}>
            <div className={style.none}>
              {LampSvg()}
              Данная функция находится в разработке
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={style.searchContent}>
          <SearchCard
            img={profileImg01}
            imgWebp={profileImg01Webp}
            name={{
              name: 'Александр',
              surname: 'Евдокимов'
            }}
            rating={'5.0'}
            comments={'217'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-пародонтолог',
              'Стоматолог-ортопед'
            ]}
            price={'3 500'}
            distance={'7'}
            lang={{
              first: 'Русский - C2',
              second: 'Английский - B2'
            }}
          />
          <SearchCard
            img={profileImg05}
            imgWebp={profileImg05Webp}
            name={{
              name: 'Альберт',
              surname: 'Исаев'
            }}
            rating={'5.0'}
            comments={'114'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-ортопед',
            ]}
            price={'2 500'}
            distance={'14'}
            lang={{
              first: 'Русский - C2',
              second: 'Испанский - C1 '
            }}
          />
          <SearchCard
            img={profileImg09}
            imgWebp={profileImg09Webp}
            name={{
              name: 'Эрик',
              surname: 'Логинов'
            }}
            rating={'4.7'}
            comments={'17'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-пародонтолог',
            ]}
            price={'1 500'}
            distance={'4'}
            lang={{
              first: 'Русский - C2',
              second: ''
            }}
          />
          <SearchCard
            img={profileImg08}
            imgWebp={profileImg08Webp}
            name={{
              name: 'Артем',
              surname: 'Русаков'
            }}
            rating={'4.8'}
            comments={'23'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-пародонтолог',
              'Стоматолог-имплантолог'
            ]}
            price={'1 500'}
            distance={'3'}
            lang={{
              first: 'Русский - C2',
              second: 'Украинский - C2'
            }}
          />
          <SearchCard
            img={profileImg14}
            imgWebp={profileImg14Webp}
            name={{
              name: 'Егор',
              surname: 'Горбачёв'
            }}
            rating={'4.5'}
            comments={'34'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-пародонтолог',
              'Стоматолог-хирург'
            ]}
            price={'1 500'}
            distance={'3'}
            lang={{
              first: 'Русский - C2',
              second: 'Английский - B1'
            }}
          />
          <SearchCard
            img={profileImg06}
            imgWebp={profileImg06Webp}
            name={{
              name: 'Герасим',
              surname: 'Носов'
            }}
            rating={'4.9'}
            comments={'26'}
            pos={[
              'Стоматолог-терапевт',
              'Стоматолог-пародонтолог',
              'Стоматолог-хирург'
            ]}
            price={'2 700'}
            distance={'1'}
            lang={{
              first: 'Русский - C2',
              second: ''
            }}
          />
        </div>
      </div>
    </>
  )
}

const SearchTop = () => {
  return (
    <div className={style.top}>
      <div className="container">
        <div className={style.topBody}>
          <div className={style.topBody__lens}>
            <img src={lensImg} alt="" />
          </div>
          <div className={style.topBody__search}>
            {/* <span>
              Искать?
            </span>
            Кого вы хотите найти */}
            Поиск специалиста
          </div>
          <div className={style.topBody__options}>
            <img src={optionsImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchCard = ({ img, imgWebp, name, rating, comments, pos, price, distance, lang }) => {
  const dispath = useDispatch()

  const posList = pos.map((item, index) => {
    return (
      <li key={index} className={style.cardPos__item}>
        {item}
      </li>
    )
  })

  return (
    <div className={style.card}>
      <div className={style.cardTop}>
        <div className={style.cardTop__img}>
          <ImgWebp
            srcWebp={imgWebp}
            src2x={img}
            src={img}
          />
        </div>
        <div className={style.cardTop__info}>
          <div className={style.cardTop__infoName}>
            {name.name}
            <br />
            {name.surname}
          </div>
          <div className={style.cardTop__infoContent}>
            <div className={style.cardTop__infoRating}>
              {StarSvg()}
              {rating}
            </div>
            <div className={style.cardTop__infoComments}>
              {CommentsSvg()}
              {comments}
            </div>
          </div>
        </div>
      </div>
      <ul className={style.cardPos}>
        {posList}
      </ul>
      <div className={style.cardAdvantages}>
        <div className={`${style.cardAdvantages__item} ${style._yellow}`}>
          Бесплатная консультация
        </div>
        <div className={style.cardAdvantages__item}>
          Бесплатные снимки
        </div>
      </div>
      <div className={style.cardInfo}>
        <div className={style.cardInfo__item}>
          <div className={style.cardInfo__itemName}>
            Стоимость приёма
          </div>
          <div className={style.cardInfo__itemValue}>
            {price} руб. <span onClick={() => dispath(openModal())}>Полный прайс</span>
          </div>
        </div>
        <div className={style.cardInfo__item}>
          <div className={style.cardInfo__itemName}>
            Расстояние от вас
          </div>
          <div className={style.cardInfo__itemValue}>
            {distance} км
          </div>
        </div>
        <div className={style.cardInfo__item}>
          <div className={style.cardInfo__itemName}>
            Знание языков
          </div>
          <div className={style.cardInfo__itemValue}>
            <ul>
              <li>
                {lang.first}
              </li>
              <li>
                {lang.second}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={style.cardBtns}>
        <div
          onClick={() => dispath(openModal())}
          className={style.cardBtns__item}>
          Написать
        </div>
        <div
          onClick={() => dispath(openModal())}
          className={`${style.cardBtns__item} ${style._blue}`}>
          Записаться
        </div>
      </div>
    </div>
  )
}

export default SearchScreenMobile