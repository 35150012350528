import style from "./User.module.scss"

// imgs import
import GalleryAddIcon from "@assets/img/icons/gallery-add.svg"
import CallIcon from "@assets/img/icons/call.svg"
import SmsIcon from "@assets/img/icons/sms.svg"
import HistoryIcon from "@assets/img/icons/history.svg"
import PersonalCardIcon from "@assets/img/icons/personalcard.svg"
import FavoriteIcon from "@assets/img/icons/favorite.svg"
import PaymentIcon from "@assets/img/icons/payment.svg"
import NotificationIcon from "@assets/img/icons/notification.svg"
import SupportIcon from "@assets/img/icons/message.svg"
import FaqIcon from "@assets/img/icons/search-status.svg"
import ArrowIcon from "@assets/img/icons/arrow.svg"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/modal"

const UserScreenMobile = () => {
  const dispath = useDispatch()

  return (
    <div className={style.user}>
      <div className="container">
        <div className={style.userBody}>
          <div className={style.userBody__top}>
            <div className={style.userBody__topImg}>
              <img src={GalleryAddIcon} alt="" />
            </div>
            <div className={style.userBody__topName}>
              Имя Фамилия
            </div>
            <div className={style.userBody__topPos}>
              Пол, Возраст
            </div>
          </div>
          <div className={style.userBody__btns}>
            <div className={`${style.userBody__btnsItem} ${style.active}`}>
              Личные данные
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__btnsItem}>
              Настройки
            </div>
          </div>

          <div className={style.userBody__links}>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={CallIcon} alt="" />
              </div>
              Введите ваш номер
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={SmsIcon} alt="" />
              </div>
              Привяжите вашу почту
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
          </div>

          <div className={style.userBody__links}>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={HistoryIcon} alt="" />
              </div>
              История лечения
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={PersonalCardIcon} alt="" />
              </div>
              Медицинская карта
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={FavoriteIcon} alt="" />
              </div>
              Избранные
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={PaymentIcon} alt="" />
              </div>
              Платежи
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={NotificationIcon} alt="" />
              </div>
              Уведомления
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
          </div>

          <div className={style.userBody__links}>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={SupportIcon} alt="" />
              </div>
              Помощь
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
            <div onClick={() => dispath(openModal())} className={style.userBody__linksItem}>
              <div className={style.icon}>
                <img src={FaqIcon} alt="" />
              </div>
              FAQ
              <div className={style.arrow}>
                <img src={ArrowIcon} alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default UserScreenMobile