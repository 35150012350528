import style from "./Header.module.scss"
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// imgs import
import logoImg from "@assets/img/header/logoLight.svg"
import LangSvg from "@assets/img/header/langSvg.js"
import LensImg from "@assets/img/icons/lens.svg"
import LampSvg from "@assets/img/icons/lampSvg"
import XImg from "@assets/img/icons/ex_blue.svg"
import ArrowSvg from "@assets/img/icons/arrowSvg.js"

import MenuImg from "@assets/img/header/menu.svg"
import UserImg from "@assets/img/header/user.svg"
import { useDispatch } from "react-redux";
import { openModal } from "../../store/modal";

const dateObject = {
  november: {
    monthName: 'Ноябрь',
    numbers: [{ num: '', isDisable: true }, { num: 1, isDisable: true }, { num: 2, isDisable: true }, { num: 3, isDisable: true }, { num: 4, isDisable: true }, { num: 5, isDisable: true }, { num: 6, isDisable: true }, { num: 7, isDisable: true }, { num: 8, isDisable: true }, { num: 9, isDisable: true }, { num: 10, isDisable: true }, { num: 11, isDisable: true }, { num: 12, isDisable: true }, { num: 13, isDisable: true }, { num: 14, isDisable: true }, { num: 15, isDisable: true }, { num: 16, isDisable: true }, { num: 17, isDisable: true }, { num: 18, isDisable: true }, { num: 19, isDisable: true }, { num: 20, isDisable: true }, { num: 21, isDisable: true }, { num: 22, isDisable: true }, { num: 23, isDisable: true }, { num: 24, isDisable: true }, { num: 25, isDisable: true }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }]
  },
  december: {
    monthName: 'Декабрь',
    numbers: [{ num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: 1, isDisable: false }, { num: 2, isDisable: false }, { num: 3, isDisable: false }, { num: 4, isDisable: false }, { num: 5, isDisable: false }, { num: 6, isDisable: false }, { num: 7, isDisable: false }, { num: 8, isDisable: false }, { num: 9, isDisable: false }, { num: 10, isDisable: false }, { num: 11, isDisable: false }, { num: 12, isDisable: false }, { num: 13, isDisable: false }, { num: 14, isDisable: false }, { num: 15, isDisable: false }, { num: 16, isDisable: false }, { num: 17, isDisable: false }, { num: 18, isDisable: false }, { num: 19, isDisable: false }, { num: 20, isDisable: false }, { num: 21, isDisable: false }, { num: 22, isDisable: false }, { num: 23, isDisable: false }, { num: 24, isDisable: false }, { num: 25, isDisable: false }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }, { num: 31, isDisable: false }]
  },
  january: {
    monthName: 'Январь',
    numbers: [{ num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: '', isDisable: false }, { num: 1, isDisable: false }, { num: 2, isDisable: false }, { num: 3, isDisable: false }, { num: 4, isDisable: false }, { num: 5, isDisable: false }, { num: 6, isDisable: false }, { num: 7, isDisable: false }, { num: 8, isDisable: false }, { num: 9, isDisable: false }, { num: 10, isDisable: false }, { num: 11, isDisable: false }, { num: 12, isDisable: false }, { num: 13, isDisable: false }, { num: 14, isDisable: false }, { num: 15, isDisable: false }, { num: 16, isDisable: false }, { num: 17, isDisable: false }, { num: 18, isDisable: false }, { num: 19, isDisable: false }, { num: 20, isDisable: false }, { num: 21, isDisable: false }, { num: 22, isDisable: false }, { num: 23, isDisable: false }, { num: 24, isDisable: false }, { num: 25, isDisable: false }, { num: 26, isDisable: false }, { num: 27, isDisable: false }, { num: 28, isDisable: false }, { num: 29, isDisable: false }, { num: 30, isDisable: false }, { num: 31, isDisable: false }]
  },
}

const Header = ({ setIsModalOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isSearchPage, setIsSearchPage] = useState(false)
  const [currentSearchType, setCurrentSearchType] = useState(1)
  const [currentTab, setCurrentTab] = useState(1)
  const [currentDate, setCurrentDate] = useState({ date: null, month: 'Декабрь' })
  const navigate = useNavigate()
  const location = useLocation()
  const city = useRef('')

  const [isSearchDisable, setIsSearchDisable] = useState(false)

  const dispath = useDispatch()

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const toPage = (page) => {
    navigate(page)
  }

  const search = () => {
    setIsSearchPage(true)
    navigate('search')
    setIsSearchOpen(false)
  }

  const openSearch = (num) => {
    setIsSearchOpen(true)
    setCurrentSearchType(num)
  }

  const toggleDate = (e) => {
    if (!e.target.classList.contains(style._disable)) {
      const dateList = document.querySelectorAll('._calendar__item')
      let month = e.target.dataset.month.slice(0, 3)
      setCurrentDate({ date: e.target.innerHTML, month: `${month}.` })
      if (e.target.classList.contains(style._active)) {
        e.target.classList.remove(style._active)
      } else {
        dateList.forEach(item => {
          item.classList.remove(style._active)
        })
        e.target.classList.add(style._active)
      }
    }
  }

  useEffect(() => {
    if (location.pathname === '/search') {
      setIsSearchPage(true)
    } else {
      setIsSearchPage(false)
    }
    if (location.pathname === '/chat') {
      setIsSearchDisable(true)
    } else {
      setIsSearchDisable(false)
    }
  })

  return (
    <>
      <div
        onClick={() => setIsSearchOpen(!isSearchOpen)}
        className={`${style.searchBg} ${isSearchOpen ? style.active : ''}`}></div>
      <header className={style.header}>
        <div className="container">
          <div className={`
          ${style.headerBody} 
          ${isSearchOpen || isSearchPage ? style.active : ''}
          `}>
            <div onClick={() => toPage('/')} className={style.headerBody__logo}>
              <img src={logoImg} alt="" />
            </div>

            <div
              className={`
              ${style.headerBody__search} 
              ${isSearchOpen ? style.active : ''}
              ${isSearchPage ? style._hidden : ''}
              ${isSearchDisable ? style.disable : ''}
              `}
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <div className={style.headerBody__searchImg}>
                <img src={LensImg} alt="" />
              </div>
              <div className={style.headerBody__searchText}>
                <span>Искать стоматолога</span>
                <span>Выбрать дату</span>
                <span>Выбрать город</span>
              </div>
            </div>

            <div className={`
            ${style.search} 
            ${isSearchOpen ? style.active : ''}
            ${isSearchDisable ? style.disable : ''}`}>
              <div className={style.searchTab}>
                <div onClick={() => setCurrentTab(1)}
                  className={`
                ${style.searchTab__item} 
                ${currentTab === 1 ? style.active : ''}`}>
                  Запись
                </div>
                <div onClick={() => setCurrentTab(2)}
                  className={`
                ${style.searchTab__item}
                ${currentTab === 2 ? style.active : ''}
                `}>
                  Аудит
                </div>
              </div>
              <div className={style.searchBtns}>
                <div
                  onClick={() => setCurrentSearchType(1)}
                  className={`${style.searchBtns__item} ${style._input} ${currentSearchType === 1 ? style._active : ''}`}>
                  Искать стоматолога
                  <input type="text" placeholder="Введите запрос" />
                </div>
                <div
                  onClick={() => setCurrentSearchType(2)}
                  className={`${style.searchBtns__item} ${currentSearchType === 2 ? style._active : ''}`}>
                  {currentDate.date === null ? 'Выбрать дату' : `${currentDate.date} ${currentDate.month}`}
                </div>
                <div
                  onClick={() => setCurrentSearchType(3)}
                  className={`${style.searchBtns__item} ${currentSearchType === 3 ? style._active : ''} ${style._input} ${style._data}`}>
                  Выбрать город
                  <input ref={city} type="text" placeholder="Введите город" />
                </div>
                <div
                  onClick={search}
                  className={`${style.searchBtns__item} ${style._search}`}>
                  Искать
                </div>
                <div
                  onClick={search}
                  className={`${style.searchBtns__item} ${style._search} ${style._fake} ${currentSearchType === 3 ? style._hidden : ''}`}>
                  Искать
                </div>
              </div>
              <div className={`${style.searchContent} ${currentSearchType === 3 ? style.hidden : ''}`}>
                <div className={`${style.searchContent__item} ${currentSearchType === 1 ? style.active : ''}`}>
                  <div className={`${style.querySearch}`}>
                    <div className={`${style.querySearch__col}`}>
                      <div className={`${style.querySearch__title}`}>
                        Недавние запросы
                      </div>
                      <div className={style.none}>
                        {LampSvg()}
                        У вас нет истории запросов
                      </div>
                    </div>
                    <div className={`${style.querySearch__col}`}>
                      <div className={`${style.querySearch__title}`}>
                        Поиск по шаблону
                      </div>
                      <div className={`${style.querySearch__check}`}>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Стоматолог-терапевт
                        </label>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Стоматолог-пародонтолог
                        </label>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Стоматолог-ортопед
                        </label>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Стоматолог-имплантолог
                        </label>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Стоматолог-хирург
                        </label>
                        <label>
                          <input type="checkbox" />
                          <span className={style.checkbox}>
                            <img src={XImg} alt="" />
                          </span>
                          Детский стоматолог
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${style.searchContent__item} ${currentSearchType === 2 ? style.active : ''}`}>
                  <div className={style.queryCalendar}>
                    <div className={style.queryCalendar__title}>
                      Дата приема
                    </div>
                    <div className={style.queryCalendar__body}>

                      <div ref={navigationPrevRef} className={style.queryCalendar__bodyPrev}>
                        {ArrowSvg()}
                      </div>
                      <div ref={navigationNextRef} className={style.queryCalendar__bodyNext}>
                        {ArrowSvg()}
                      </div>

                      <Swiper
                        modules={[Navigation]}
                        spaceBetween={46}
                        slidesPerView={2}
                        navigation={{
                          nextEl: navigationNextRef.current,
                          prevEl: navigationPrevRef.current,
                        }}
                        autoHeight={true}
                        allowTouchMove={false}
                      >
                        <SwiperSlide>
                          <div className={style._calendar}>
                            <div className={style._calendar__title}>
                              {dateObject.november.monthName} 2022 г.
                            </div>
                            <div className={style._calendarBody}>
                              <div className={style._calendarBody__week}>
                                <div className={style._calendarBody__weekItem}>
                                  Пн
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Ср
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Чт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Пт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Сб
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вс
                                </div>
                              </div>
                              <div className={style._calendarBody__content}>
                                {dateObject.november.numbers.map((item, index) => <div
                                  key={index}
                                  onClick={(e) => toggleDate(e)}
                                  data-month={dateObject.november.monthName}
                                  className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.november.numbers[index].isDisable ? style._disable : ''}
                                `}>
                                  {dateObject.november.numbers[index].num}
                                </div>)}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className={style._calendar}>
                            <div className={style._calendar__title}>
                              {dateObject.december.monthName} 2022 г.
                            </div>
                            <div className={style._calendarBody}>
                              <div className={style._calendarBody__week}>
                                <div className={style._calendarBody__weekItem}>
                                  Пн
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Ср
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Чт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Пт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Сб
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вс
                                </div>
                              </div>
                              <div className={style._calendarBody__content}>
                                {dateObject.december.numbers.map((item, index) => <div
                                  key={index}
                                  data-month={dateObject.december.monthName}
                                  onClick={(e) => toggleDate(e)}
                                  className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.december.numbers[index].isDisable ? style._disable : ''}
                                `}>
                                  {dateObject.december.numbers[index].num}
                                </div>)}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className={style._calendar}>
                            <div className={style._calendar__title}>
                              {dateObject.january.monthName} 2023 г.
                            </div>
                            <div className={style._calendarBody}>
                              <div className={style._calendarBody__week}>
                                <div className={style._calendarBody__weekItem}>
                                  Пн
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Ср
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Чт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Пт
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Сб
                                </div>
                                <div className={style._calendarBody__weekItem}>
                                  Вс
                                </div>
                              </div>
                              <div className={style._calendarBody__content}>
                                {dateObject.january.numbers.map((item, index) => <div
                                  key={index}
                                  data-month={dateObject.january.monthName}
                                  onClick={(e) => toggleDate(e)}
                                  className={`
                                ${style._calendarBody__contentItem}
                                _calendar__item
                                ${dateObject.january.numbers[index].isDisable ? style._disable : ''}
                                `}>
                                  {dateObject.january.numbers[index].num}
                                </div>)}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`
            ${style.searchReady} 
            ${isSearchPage ? style._active : ''}
            ${isSearchOpen ? style._hidden : ''}
            `}>
              <div onClick={() => openSearch(1)} className={style.searchReady__item}>
                Выбран
              </div>
              <div onClick={() => openSearch(2)} className={style.searchReady__item}>
                {/* {currentDate} */}
                {currentDate.date === null ? 'Выбрать дату' : `${currentDate.date} ${currentDate.month}`}
              </div>
              <div onClick={() => openSearch(3)} className={style.searchReady__item}>
                {city.current.value}
              </div>
              <div className={`${style.searchReady__item} ${style._btn}`}>
                Искать
              </div>
            </div>

            <div onClick={() => {
              dispath(openModal())
              window.gtag('event', 'click', { event_category: 'clickBtn' })
            }} className={style.headerBody__lang}>
              {LangSvg()}
            </div>
            <div className={style.headerBody__profile} onClick={() => setIsMenuOpen(!isMenuOpen)}>

              <div
                onMouseLeave={() => setIsMenuOpen(false)}
                className={`
            ${style.headerBody__menu} 
            ${isMenuOpen ? style._open : ''}
            `}>
                <div onClick={() => dispath(openModal())} className={style.headerBody__menuItem}>
                  Зарегистрироваться
                </div>
                <div onClick={() => dispath(openModal())} className={style.headerBody__menuItem}>
                  Войти
                </div>
                <div onClick={() => toPage('chat')} className={style.headerBody__menuItem}>
                  Сообщения
                </div>
                <div onClick={() => dispath(openModal())} className={style.headerBody__menuItem}>
                  Медицинская карта
                </div>
                <div onClick={() => dispath(openModal())} className={style.headerBody__menuItem}>
                  Помощь
                </div>
              </div>

              <div className={style.headerBody__profileBtn}>
                <img src={MenuImg} alt="" />
              </div>
              <div className={style.headerBody__profileUser}>
                <img src={UserImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header